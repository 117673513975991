html, body, ul, li, p, h1, h3 {
padding: 0;
margin: 0;
font-family: inherit;
list-style: none;
text-decoration: none
}



@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap" rel="stylesheet");


/* ----------- HEADER ----------- */

.header-bkgd {
background-color: #F1F2F1;
/*   min-height: 50px; */
}

.header-wrap {
margin: 0 auto;
min-height: 55px;
max-width: 1024px;
display: flex;
align-items: center;
justify-content: space-between;
}

.header-logo-pc{
display: flex;
align-items: center;
cursor: pointer;
}

.logo-pc{
width: 80px;
}

.vl {
border-left: 1px solid #9b9b9b;
width: 15px;
height: 40px;
margin-left: 10px;
}

.titulo-pc {
display: flex;
flex-direction: column;
margin-top: -3px;
}

.subtitulo-pc {
color: #CC9734;
margin-top: -3px;
}



header {
font-family: "Roboto";
font-weight: bold;
font-style: italic;
font-size: 16px
}




.header-item {
width: 500px;
font-family: 'Roboto';
font-size: 15px;
font-style: normal;
color: black;
font-weight: 400;
}



.master-wrap{
max-width: 500px;
min-height: 600px;
margin: 0 auto;
font-family: 'Roboto';
font-size: 25px;
font-style: normal;
color: black;
font-weight: 300;
}

.master-text{
/* width: 200px; */
padding-top: 40px;
text-align: center;
}





.loader-homepage-wrap{
margin-top: 60px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
}

.loader-homepage-img { 
text-align: center;
width: 60px; 
}
.loader-homepage-img img { 
width: 60px; 
}
.loader-homepage-text { 
margin-top: 20px;
margin-bottom: 20px;
text-align: center; 
font-family: "Roboto";
font-weight: 300;
font-size: 14px;;
}

